import React from "react"
import { arrayOf, shape, string } from "prop-types"
import { H1style, P } from "../../styles/typography"
import { Wrapper, Table, TableItem, HeadingWrapper } from "./styles"

const FactsList = ({ facts }) => {
  return (
    <Wrapper>
      <HeadingWrapper>
        <H1style>Bostadsfakta</H1style>
      </HeadingWrapper>
      <Table>
        {facts.map(({ title, info }, index) => (
          <TableItem key={index}>
            <P>{title}:</P>
            <P>{info}</P>
          </TableItem>
        ))}
      </Table>
    </Wrapper>
  )
}
FactsList.propTypes = {
  facts: arrayOf(shape({ title: string, info: string })).isRequired,
}

export default FactsList
