import React from "react"
import PropTypes from "prop-types"
import {
  Wrapper,
  TextContainer,
  HeadingContainer,
  // DocumentButtonWrapper,
  // DocumentButton,
} from "./styles"
import { H2, P } from "../../styles/typography"

const HeadingTextTwoColumns = ({ heading, text, wide, hasBuyButton }) => {
  return (
    <Wrapper paddingBottom={hasBuyButton}>
      <HeadingContainer wide={wide}>
        {heading && <H2>{heading}</H2>}
      </HeadingContainer>
      {/* {hasBuyButton && (
        <DocumentButtonWrapper>
          <DocumentButton to="/kopanmalan"></DocumentButton>
        </DocumentButtonWrapper>
      )} */}
      <TextContainer>
        {text.map((section, index) => (
          <P key={index} size="large">
            {section}
          </P>
        ))}
      </TextContainer>
    </Wrapper>
  )
}

HeadingTextTwoColumns.propTypes = {
  text: PropTypes.array.isRequired,
  heading: PropTypes.string,
  wide: PropTypes.bool,
  hasBuyButton: PropTypes.bool,
}

HeadingTextTwoColumns.defaultProps = {
  heading: "",
  wide: true,
  hasBuyButton: false,
}

export default HeadingTextTwoColumns
