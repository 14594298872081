import styled from "styled-components"
import { main } from "../../styles/theme"
import { gridLayout } from "../../styles/mixins"

export const Wrapper = styled.section`
  ${gridLayout}

  padding: 60px 0;

  @media ${main.breakpoints.large} {
    padding: 114px 0 110px;
  }
`

export const HeadingWrapper = styled.div`
  grid-column: 1 / -1;

  @media ${main.breakpoints.large} {
    grid-column: 1 / span 6;
  }
`

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1 / -1;

  @media ${main.breakpoints.large} {
    grid-column: 7 / span 5;
  }
`
export const TableItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.black}`};
  height: 68px;

  > p {
    white-space: ${({ breakLine }) => "break-spaces"};
    margin: ${({ breakLine }) => "0"};
    align-self: center;

    @media ${main.breakpoints.medium} {
      align-self: center;
      white-space: normal;
    }
  }
`
