import * as React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import styled from "styled-components"
import { main } from "../../styles/theme"
import { flexCenter, gridLayout } from "../../styles/mixins"

export const Wrapper = styled.section`
  position: relative;
  ${gridLayout}
  padding: ${({ paddingBottom }) =>
    paddingBottom ? "50px 0 200px" : "50px 0"};

  @media ${main.breakpoints.medium} {
    padding: 50px 0;
  }
`
export const HeadingContainer = styled.div`
  grid-column: 1 / -1;

  @media ${main.breakpoints.medium} {
    grid-column: ${({ wide }) => (wide ? "1 / span 6" : "1 / span 5")};
  }
`
export const TextContainer = styled.div`
  grid-column: 1 / -1;

  @media ${main.breakpoints.medium} {
    grid-column: 7 / span 6;
  }
`

export const DocumentButtonWrapper = styled.div`
  ${gridLayout}
  position: absolute;
  bottom: 2%;
  @media ${main.breakpoints.small} {
    bottom: 1%;
  }
  @media ${main.breakpoints.medium} {
    bottom: 9%;
  }
  @media ${main.breakpoints.large} {
  }
`

export const DocumentButton = styled(props => (
  <AniLink cover bg="#C4C4C4" {...props} />
))`
  ${flexCenter};
  display: grid;
  grid-column: 1 / span 6;
  font-size: 1rem;
  column-gap: 40px;

  position: relative;
  border-radius: 50%;
  width: 100%;
  height: auto;
  padding-top: 100%;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.black};
    transition: transform 0.2s ease;
  }

  &::after {
    color: ${({ theme }) => theme.colors.white};
    content: "Köpanmälan";
    text-align: center;
    width: 100%;
    position: absolute;
  }

  &:hover {
    &::before {
      transform: scale(1.125);
      transition: transform 0.4s ease;
    }
  }

  @media ${main.breakpoints.small} {
    grid-column: 1 / span 4;
  }
  @media ${main.breakpoints.medium} {
    grid-column: 1 / span 3;
  }

  @media ${main.breakpoints.large} {
    font-size: 1.25rem;
    grid-column: 1 / span 2;
  }

  @media ${main.breakpoints.xlarge} {
    font-size: 1.375rem;
  }
`
